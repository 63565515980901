import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchProviders(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/providers', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        createProvider(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/providers/create', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchProvider(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/providers/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateProvider(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/providers/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteProvider(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/providers/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}