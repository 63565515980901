import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useProvidersList() {

    // Use toast
    const toast = useToast();

    const refProviderListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'name', sortable: true, label: 'PROVEEDOR' },
        { key: 'ruc', sortable: true, label: 'RUC' },
        { key: 'business_area_name', sortable: true, label: 'RUBRO' },
        { key: 'contract_numbers', sortable: true, label: 'NÚMERO(S) <br/> CONTRATO(S)' },
        { key: 'next_date_cycle', sortable: true, label: 'PRÓXIMA FECHA <br/> DEL CICLO' },
        { key: 'form_count', sortable: true, label: 'CANTIDAD <br/> FORMULARIOS ASIGNADOS' },
        { key: 'actions', sortable: false, label: 'OPCIONES' }
    ];

    const perPage = ref(10);
    const totalProviders = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    const businessAreasFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refProviderListTable.value ? refProviderListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalProviders.value,
        }
    });

    const refetchData = () => {
        refProviderListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, businessAreasFilter], () => {
        refetchData();
    });

    const fetchProviders = (ctx, callback) => {
        store
            .dispatch('app-provider/fetchProviders', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                businessareas: businessAreasFilter.value
            })
            .then(response => {
                const { providers, total } = response.data;
                callback(providers);
                totalProviders.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de proveedores',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchProviders,
        tableColumns,
        perPage,
        currentPage,
        totalProviders,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refProviderListTable,

        refetchData,

        // Extra Filters
        businessAreasFilter
    }
}