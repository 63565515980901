<template>

	<div>

		<!-- Filters -->
		<providers-list-filters
			:options="options"
			:business-areas-filter.sync="businessAreasFilter"
        />
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar proveedor ..."
								trim
							/>
							<b-button
								:to="{ name: 'providers-create' }"
								variant="primary"							
								>
								<span class="text-nowrap">Agregar Proveedor</span>
							</b-button>
						</div>
					</b-col>

				</b-row>

			</div>

			<b-table
				ref="refProviderListTable"
				class="position-relative table-providers"
				:style="'height: inherit'"
				:items="fetchProviders"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>

				<template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

				<template #head(contract_numbers)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(next_date_cycle)="data">
					<span v-html="data.label"></span>
				</template>
				
				<template #head(form_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(name)="data">
					<b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                size="32"
                                :src="null"
                                :text="avatarText(data.item.name)"
                                variant="light-success"
                                :to="{ name: 'providers-edit', params: { id: data.item.id } }"
                            />
                        </template>
                        <b-link
                            :to="{ name: 'providers-edit', params: { id: data.item.id } }"
                            class="font-weight-bold d-block text-nowrap"
						>
                            {{ data.item.name }}
                        </b-link>
                    </b-media>
				</template>

				<template #cell(contract_numbers)="data">
                    {{ data.item.contract_numbers ? data.item.contract_numbers : '-' }}
				</template>

				<template #cell(next_date_cycle)="data">
                    <b-badge variant="light-primary">{{ moment(data.item.next_date_cycle).format('DD/MM/YYYY') }}</b-badge>
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item 
							:to="{ name: 'providers-edit', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteProvider(data.item.id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalProviders"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>
		
	</div>

</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
	import ProvidersListFilters from './ProvidersListFilters.vue';
	import { useToast } from 'vue-toastification/composition';
	import providerStoreModule from '../providerStoreModule';
	import useProvidersList from './useProvidersList';
	import { avatarText } from '@core/utils/filter';
	import VueSweetalert2 from 'vue-sweetalert2';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';
	import Vue from 'vue';    
	import 'animate.css';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			ProvidersListFilters,
			vSelect
		},
		data() {
            return {
                moment
            }
        },
		setup() {

			// USE TOAST
			const toast = useToast();

			// REFS
			const options = ref({});

			const PROVIDER_APP_STORE_MODULE_NAME = 'app-provider';

			// REGISTER MODULE
			if (!store.hasModule(PROVIDER_APP_STORE_MODULE_NAME)) store.registerModule(PROVIDER_APP_STORE_MODULE_NAME, providerStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(PROVIDER_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVIDER_APP_STORE_MODULE_NAME);
			});

			// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/selects/business-areas');
                options.value = data;
            };
		
			const deleteProvider = (id) => {
		
				Vue.swal({
					title: '¿Está seguro(a) de eliminar el proveedor?',
					text: "¡Si no lo está, puede cancelar la acción!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, eliminar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						store.dispatch('app-provider/deleteProvider', { id })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al eliminar el proveedor.',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};
			
			const {
				fetchProviders,
				tableColumns,
				perPage,
				currentPage,
				totalProviders,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refProviderListTable,
				refetchData,

				// EXTRA FILTERS
                businessAreasFilter
			} = useProvidersList();
			
			return {
				fetchProviders,
				tableColumns,
				options,
				perPage,
				currentPage,
				totalProviders,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refProviderListTable,
				refetchData,

				// EXTRA FILTERS
                businessAreasFilter,
				
				// ACTIONS
				avatarText,
				deleteProvider
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
        align-items: center;
    }

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>